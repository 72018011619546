<template>
  <div>
    <div class="container-inner mx-auto py-16 text-gray-800">
      <p class="text-4xl font-bold mb-6">Page Not Found!</p>
      <p class="text-xl  mb-16">
        Sorry, but the page you were looking for could not be found.
      </p>
      <img src="@/assets/illustrations/404.svg" alt="404 Page Not Found" />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Page Not Found | MedInDiet",
  },
};
</script>
